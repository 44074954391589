<template>
    <div class="row mx-0 mb-1">
        <div class="col-md-6 px-0">
            <CustomDropDown :options="filterOpt" v-if="this.localFilterValue != 'None'" :initialValue="this.localInitialValue" @item-selected="handleItemSelected" :fieldName="this.fieldName" :selectedOpt="selectedOpt"></CustomDropDown>
        </div>
        <div class="col-md-6 px-0 ps-2">
            <CustomDropDown v-if="shouldRenderDateRange && this.localFilterValue != 'None'" :options="dateRangeOption" :initialValue="this.localFilterValue" @item-selected="handleDateTimeSelect" :fieldName="this.fieldName"></CustomDropDown>
            <CustomDropDown v-else-if="this.shouldRenderSeverity && this.localFilterValue != 'None'" :options="serverityOption" :initialValue="this.localFilterValue" @item-selected="handleSeveritySelect" :fieldName="this.fieldName"></CustomDropDown>
            <CustomDropDown v-else-if="this.shouldRenderLifeStage && this.localFilterValue != 'None'" :options="lifestageOption" :initialValue="this.localFilterValue" @item-selected="handleLifeStageSelect" :fieldName="this.fieldName"></CustomDropDown>
            <CustomDropDown v-else-if="this.shouldRenderBooleanOptions" :options="booleanOptions" :initialValue="this.localFilterValue" @item-selected="handleDateTimeSelect" :fieldName="this.fieldName" :key="booleanKey"></CustomDropDown>
            <div class="position-relative" v-else-if="this.localFilterValue != 'None'">
                <input type="text" class="form-control" @input="handleInputChange" v-model="this.localFilterValue" @keyup.enter="submitForm" :readonly="this.selectedFields && !this.selectedFields[this.fieldName]">
                <i class="fa-solid fa-xmark" @click="this.removeItem(this.fieldName)"></i>
            </div>
        </div>
    </div>
</template>

<script>
import CustomDropDown from '../base/formFields/CustomDropDown.vue';

export default {
    data() {
        return {
            booleanKey:0,
            localInitialValue:this.initialValue,
            localFilterValue:this.initialValue,
            filterOpt:this.options,
            selectedFields:[],
            debounceTimeout: null, // to store the timeout ID
            dateRangeOption:[
                // { value: "All", title: "All" },
                { value: "None", title: "None" },
                { value: "Today", title: "Today" },
                { value: "Yesterday", title: "Yesterday" },
                { value: "This Week", title: "This Week" },
                { value: "Last Week", title: "Last Week" },
                { value: "Last 7 Days", title: "Last 7 Days" },
                { value: "Last 30 Days", title: "Last 30 Days" },
                { value: "Last 90 Days", title: "Last 90 Days" },
                { value: "Last 180 Days", title: "Last 180 Days" },
                { value: "This Month", title: "This Month" },
                { value: "Last Month", title: "Last Month" },
                { value: "This Year", title: "This Year" },
            ],
            lifestageOption:[
                { value: "None", title: "None" },
                { value: "", title: "Not Set" },
                { value: "N", title: "Newborn: Birth to 28 days" },
                { value: "I", title: "Infant: 29 days to 2 years" },
                { value: "C", title: "Child: 2 years to 15 years" },
                { value: "T", title: "Adolescent: 16 to 17 years" },
                { value: "A", title: "Adult: 18 years or more" },
            ],
            serverityOption:[
                { value: "None", title: "None" },
                { value: "", title: "Not Set" },
                { value: "Mild", title: "Mild" },
                { value: "Moderate", title: "Moderate" },
                { value: "Severe", title: "Severe" },
            ],
            booleanOptions:[
                // { value: "All", title: "All" },
                { value: "None", title: "None" },
                { value: "Yes", title: "Yes" },
                { value: "No", title: "No" },
            ]
        }
  },
  props: {
    options: {
        type: Array,
        required: true,
    },
    fieldName:{
        type: String,
        required: true,
    },
    initialValue:{
        type: String,
        required: true,  
    },
    selectedOpt:{
        type: Array,
        required: true, 
    }
  },
  components:{
    CustomDropDown
  },
  methods:{
    handleItemSelected({ fieldName, item }) {
        this.selectedFields[fieldName] = item.value
        item.field = fieldName
        this.$emit('array-updated', item);
        if (item.value == 'received_timerange' || item.value == 'updated_datetime' || item.value == 'is_past_med' || item.value == 'is_long_term') {
            // this.localFilterValue = 'All'
            this.localFilterValue = 'Not Set'
        } else if(item.value == 'severity_of_reaction' || item.value == 'life_stage') {
            this.localFilterValue = 'Not Set'
        } else {
            this.localFilterValue = ''
        }


        const excludedValues = ['received_timerange', 'updated_datetime', 'severity_of_reaction', 'life_stage', 'is_past_med', 'is_long_term'];

        // Handle non-excluded fields
        if (excludedValues.includes(item.value)) {
            // this.$emit('text-updated', {'field':this.fieldName, 'value':'All'});
            this.$emit('text-updated', {'field':fieldName, 'value':'Not Set'});
        } else {
            this.$emit('text-updated', {'field':fieldName, 'value':''});
        }

        if (item.value == 'is_past_med' || item.value == 'is_long_term') {
            this.localFilterValue = 'Not Set'
            this.booleanKey+=1
            this.submitForm()
        }
    },
    handleDateTimeSelect({ fieldName, item }) {
        // Clear the previous timeout if the user keeps typing
        clearTimeout(this.debounceTimeout);

        this.$emit('text-updated', {'field':this.fieldName, 'value':item.value});
        if (item.value == 'None') {
            this.removeItem(fieldName)
        }
        this.submitForm()
    },
    handleSeveritySelect({ fieldName, item }) {
        // Clear the previous timeout if the user keeps typing
        clearTimeout(this.debounceTimeout);

        this.$emit('text-updated', {'field':this.fieldName, 'value':item.value});
        if (item.value == 'None') {
            this.removeItem(fieldName)
        }
        this.submitForm()
    },
    handleLifeStageSelect({ fieldName, item }) {
        // Clear the previous timeout if the user keeps typing
        clearTimeout(this.debounceTimeout);

        this.$emit('text-updated', {'field':this.fieldName, 'value':item.value});
        if (item.value == 'None') {
            this.removeItem(fieldName)
        }
        this.submitForm()
    },
    handleInputChange(event){
        const newValue = event.target.value;
        this.$emit('text-updated', {'field':this.fieldName, 'value':newValue});
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {        
            // Clear the previous timeout if the user keeps typing
            this.submitForm();
        }, 1000);  // 1 second delay
    },
    removeItem(currentIndex){
        if (this.selectedFields?.[currentIndex] === undefined) {
            // Handle the case when it is undefined
            const remainingOptions = this.selectedOpt;
            this.$emit('remove-item', remainingOptions);
            return false
        }

        this.selectedFields.forEach((value, index) => {
            if(value == 'life_stage' || value == 'severity_of_reaction') {
                currentIndex = index;
                const targetField = this.selectedOpt[currentIndex].field;
                // Filter out items where item.value matches the target field
                const remainingOptions = this.selectedOpt.filter(item => item.field !== targetField);
                this.$emit('remove-item', remainingOptions);
            } else {
                if (this.selectedFields[currentIndex] && this.selectedOpt[currentIndex]) {
                    const targetField = this.selectedOpt[currentIndex].field;

                    // Filter out items where item.value matches the target field
                    const remainingOptions = this.selectedOpt.filter(item => item.field !== targetField);

                    this.$emit('remove-item', remainingOptions);
                } 
                else {
                    this.$emit('remove-empty-item', currentIndex);
                }
            }
        });

        this.submitForm()
        
    },
    submitForm(){
        // Clear the previous timeout if the user keeps typing
        clearTimeout(this.debounceTimeout);
        this.$emit('request-filter');
    },
    
  },
  created() {
        console.log(this.selectedFields)
        const selectedField = this.selectedOpt[this.fieldName];

        // Handle specific field values for 'received_timerange' and 'updated_datetime'
        // if (selectedField && ['received_timerange', 'updated_datetime'].includes(selectedField.field)) {
        //     this.localFilterValue = 'All';
            
        // }

        // Filter options based on the selected options
        this.filterOpt = this.options.filter(option => 
        !this.selectedOpt.some(selected => selected.field === option.value)
        );

        const excludedValues = ['received_timerange', 'updated_datetime', 'severity_of_reaction', 'life_stage', 'is_past_med', 'is_long_term'];

        // Handle non-excluded fields
        if (selectedField && selectedField.field && !excludedValues.includes(selectedField.field)) {
            const foundObject = this.options.find(item => item.value === selectedField.field);
            if (foundObject) {
                this.handleItemSelected({
                    fieldName: this.fieldName, 
                    item: { value: selectedField.field, title: foundObject.title, field: this.fieldName }
                });
                this.$emit('text-updated', { field: this.fieldName, value: selectedField.value });
                this.localInitialValue = foundObject.title;
                this.localFilterValue = selectedField.value;
            }
        }

        // Handle excluded fields like 'life_stage' and 'severity_of_reaction'
        if (selectedField && selectedField.value && excludedValues.includes(selectedField.field)) {
            const foundObject = this.options.find(item => item.value === selectedField.field);
            if (foundObject) {
                this.handleItemSelected({
                    fieldName: this.fieldName, 
                    item: { value: selectedField.field, title: foundObject.title, field: this.fieldName }
                });
                this.localInitialValue = foundObject.title;
                this.$emit('text-updated', { field: this.fieldName, value: selectedField.value });
            }

            this.localFilterValue = selectedField.value;

            if (selectedField.field === 'life_stage') {
                const data = this.lifestageOption.find(item => item.value === selectedField.value);
                if (data) this.localFilterValue = data.title;
            }
        }
  },
  computed: {
    shouldRenderDateRange() {
        return this.selectedFields[this.fieldName] && 
            ['received_timerange', 'updated_datetime'].includes(this.selectedFields[this.fieldName]);
    },
    shouldRenderSeverity() {
        return this.selectedFields[this.fieldName] === 'severity_of_reaction';
    },
    shouldRenderLifeStage() {
        return this.selectedFields[this.fieldName] === 'life_stage';
    },
    shouldRenderBooleanOptions() {
        return ['is_past_med', 'is_long_term'].includes(this.selectedFields[this.fieldName]);
    }
  }

}

</script>